import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import '../css/home.css';
import ThreeJSCSS3DSprites from './juegos/ThreeJSCSS3DSprites';

function Home() {
  const technologies = ['Java', 'Python', 'React', 'Node.js', 'SQL', 'Git', 'AI-assisted development', 'Flutter'];
  const [latestPost, setLatestPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/api/posts?page=1&pageSize=1`);
        const data = await response.json();

        if (data.posts?.[0]) {
          setLatestPost(data.posts[0]);
        }
      } catch (error) {
        console.error('Error fetching latest blog post:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestPost();
  }, [apiUrl]);

  return (
    <div className="home-container">
      <header className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="main-title">
              <span className="greeting">Hello, I'm</span>
              <span className="name">Dante Collazzi</span>
            </h1>
            <p className="subtitle">Creating digital experiences through code</p>
            <div className="role-tags">
              <span>Software Developer</span>
              <span>Educator</span>
              <span>Tech Enthusiast</span>
            </div>
            <div className="cta-buttons">
              <a href="#projects" className="cta-button primary">View Projects</a>
              <Link to="/blog" className="cta-button secondary">Read Blog</Link>
            </div>
          </div>
          <div className="hero-visual">
            <ThreeJSCSS3DSprites />
          </div>
        </div>
        <div className="scroll-indicator">
          <div className="mouse"></div>
          <span>Scroll to explore</span>
        </div>
      </header>

      <section id="about" className="about-section">
        <h2 className="section-title">About Me</h2>
        <div className="about-content">
          <div className="about-text">
            <p>
              Hello! I'm Dante, a passionate developer with years of experience in creating elegant solutions
              to complex problems. My journey in tech has been driven by curiosity and a desire to make a positive impact.
            </p>
            <p>
              With a strong foundation in Java and Python, I've honed my skills across various aspects of software development.
              From building robust backend systems to crafting intuitive user interfaces, I approach each project with
              enthusiasm and attention to detail.
            </p>
            <p>
              My experience includes a rewarding period as a programming instructor, which broadened my perspective
              and enhanced my ability to communicate complex concepts effectively.
            </p>
            <p>
              Recently, I've been exploring AI-assisted development tools, integrating them into my workflow
              to enhance productivity and push the boundaries of what's possible in software development.
            </p>
          </div>
          <div className="skills-container">
            <h3>Tech Stack</h3>
            <div className="technologies">
              {technologies.map((tech, index) => (
                <span key={index} className="tech-item">
                  {tech}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="latest-post" className="w-full bg-[#0a192f] py-8">
        <div className="w-[90%] max-w-[800px] mx-auto">
          <div className="flex flex-col items-center text-center">
            <h2 className="text-2xl font-bold text-white mb-4">Latest Post</h2>

            {isLoading ? (
              <div className="animate-pulse w-full">
                <div className="h-16 bg-slate-800/50 rounded"></div>
              </div>
            ) : latestPost ? (
              <Link
                to={`/blog/${latestPost.id}`}
                className="block w-full"
              >
                <div className="bg-slate-800/20 hover:bg-slate-800/30 transition-colors p-4 rounded text-center">
                  <h3 className="text-white text-lg font-medium">
                    {latestPost.title}
                  </h3>
                </div>
              </Link>
            ) : (
              <div className="text-slate-400 text-center">
                No posts available
              </div>
            )}

            <Link to="/blog" className="text-blue-400 hover:text-blue-300 text-sm mt-4">
              View all →
            </Link>
          </div>
        </div>
      </section>

      <section id="projects" className="projects-section">
        <h2 className="section-title">Featured Projects</h2>
        <div className="projects-grid">
          <div className="project-card">
            <h3>3D Online Football Game</h3>
            <p>
              A multiplayer 3D football game built with Three.js and Socket.IO. Features real-time
              multiplayer gameplay, team selection system, and interactive 3D graphics. Players can
              join different rooms and compete in dynamic football matches.
            </p>
            <a
              href="https://github.com/PitiGo/frontend_futball_3d_online"
              className="project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </a>
          </div>

          <div className="project-card">
            <h3>Personal Website</h3>
            <p>
              This website showcases my journey as a developer, featuring a blog and
              embedded games, built with React and modern web technologies.
            </p>
            <a
              href="https://github.com/PitiGo/petalo9.com-Frontend"
              className="project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </a>
          </div>

          <div className="project-card">
            <h3>Easy Budget</h3>
            <p>
              A modern web application for personal budget management with real-time banking integration.
              Inspired by YNAB (You Need A Budget), it allows users to connect their bank accounts
              and effectively manage their budget through an intuitive interface.
            </p>
            <a
              href="https://github.com/PitiGo/Presupuesto_facil"
              className="project-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </a>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="social-links">
          <a href="https://github.com/PitiGo" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://x.com/DAcerbus" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
